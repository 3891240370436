import { SurveyAnswerInstance, SurveyResult } from "app/surveys/modules/state/model/Model"
import { Localization } from "core/modules/localization/Localization"
import { TranslatedString } from "core/modules/state/model/Model"
import { followUpIdentifyingQuestion } from "./WellbeingCommonReportUtils"

interface Answer {
  category?: TranslatedString
  options?: Array<{ points?: number; title?: TranslatedString }>
  question_key?: string
  question_meta?: Record<string, any>
}

interface Question {
  key?: string
  options?: Array<{ points?: number }>
}

export const ReportType = {
  ACTION: "action",
  IMPACT: "impact"
}

export enum TypeOfReport {
  INITIAL = "initial",
  FOLLOW_UP = "follow_up"
}

export interface ReportStructure {
  type: TypeOfReport
  previousAnswer?: SurveyAnswerInstance
}

export const getAnswersAveragePoints = <T extends Answer>(answers: T[]) => {
  // Todo: This may create an invalid result if 0 points is a valid result. We need to make sure
  // not to provide answers that should not be counted towards the average
  const points = answers?.map(getAnswerPoints).filter(Boolean)
  // This isn't quite right as the max value is capped to 5 points. We might need to take max
  // value as a parameter and convert all results to a range from 1 to max value
  const totalPoints = points?.reduce((total, points) => total + Math.min(5, points), 0)

  return totalPoints / (points.length || 1)
}

// Use fixed value for now, the data isn't available from server at this time
export const getCategoryMaxPoints = <T extends Answer>(answers: T[] | undefined, categoryPrefix: string) =>
  (answers?.length ?? 0) > 0 ? 5 : undefined

export const getCategoryAveragePoints = <T extends Answer>(answers: T[] | undefined, categoryPrefix: string) => {
  if (!answers) return undefined

  answers = answersByCategory(answers, categoryPrefix)
  return getAnswersAveragePoints(answers)
}

export const answersByCategory = <T extends Answer>(answers: T[] | undefined, categoryPrefix: string) =>
  answers?.filter(a => a.question_key?.startsWith(categoryPrefix + "_")) ?? []

export const answersByCategoryName = <T extends Answer>(answers: T[], categoryEn: string) =>
  answers?.filter(a => a.category?.en?.toLowerCase() === categoryEn.toLowerCase()) ?? []

export const answersByReportType = <T extends Answer>(answers: T[] | undefined | null, reportType: string) =>
  answers?.filter(a => a.question_meta?.report_type === reportType) ?? []

export const answersWithPoints = <T extends Answer>(answers: T[]) => answers?.filter(a => !!getAnswerPoints(a))

export const getAnswerPoints = <T extends Answer>(answer: T) =>
  answer?.options
    ?.map(option => option?.points ?? 0)
    .reduce((total, points) => total + points, answer.question_key === "sr_practices" ? 1 : 0) ?? 0

export const getAnswerTitle = <T extends Answer>(answer: T, localization: Localization) =>
  answer?.options
    ?.map(option => option.title && localization.txt(option.title!))
    .filter(Boolean)
    .join(", ")

export const getAnswerMaxPoints = (answer: Answer) => {
  if (answer.question_key === "sr_practices") {
    // This is a checkbox question with 7 options
    return 7
  }
  if (
    [
      "ls_ladder",
      "wp_ladder",
      "gm_change_need",
      "gm_change_motivation",
      "gm_change_capability",
      "gm_change_support"
    ].includes(answer.question_key!)
  ) {
    return 10
  }

  // The default answer max score is 5 points
  return 5
}

export const pointsSorter = (a, b) => getAnswerPoints(a) - getAnswerPoints(b)

export const getReportType = (surveyResult: SurveyResult): TypeOfReport => {
  if (surveyResult?.instances[0]?.answers?.some(answer => answer.question_key === followUpIdentifyingQuestion)) {
    return TypeOfReport.FOLLOW_UP
  } else {
    return TypeOfReport.INITIAL
  }
}

export const getScoreForAnswers = (answer: Answer) => {
  return answer.options?.reduce((total, option) => total + option.points!, 0)
}

export const getScoreColorSetpointsForAnswer = (answer: Answer) => {
  if (getAnswerMaxPoints(answer) === 10) return { darkGreen: 9, lightGreen: 8, yellow: 6, orange: 4, red: 1 }
  else if (getAnswerMaxPoints(answer) === 7) return { darkGreen: 6, lightGreen: 4, yellow: 3, orange: 2, red: 1 }

  return { darkGreen: 5, lightGreen: 4, yellow: 3, orange: 2, red: 1 }
}

export const getPersonalReportCategoryDescription = (category: string, localization: Localization, appName: string) => {
  return localization.txt(appName, "report", "common", "category_descriptions", category)
}
